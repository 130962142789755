import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Carousel from 'react-bootstrap/Carousel'

const GatesPage = () => (
  <Layout activeItem="Ворота и шлагбаумы">
    <Seo title="Ворота и шлагбаумы"/>
    <h1>Ворота и шлагбаумы</h1>
    <Carousel pause="false" interval="3000">
      <Carousel.Item>
        <img src="/images/gates/gates1.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/gates/gates2.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/gates/gates3.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/gates/gates4.jpg" className="d-block w-100" alt="..."/>
      </Carousel.Item>
    </Carousel>
    </Layout>
)

export default GatesPage
